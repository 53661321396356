import React, { FC, useState, useEffect, ChangeEvent } from 'react';
import { GeoCsv } from './GeoCsv';
import { CsvTable } from './CsvTable';
import { GeoRadio } from './GeoRadio';
import { useGeo } from '../../context';
import { GeoSelect } from './GeoSelect';
import { GeoActions } from './GeoActions';
import { GeoTextInput } from './GeoTextInput';
import { useTranslation } from 'react-i18next';
import { GeoPercentage } from './GeoPercentage';
import { RowDescription } from './RowDescription';
import { GeoMultiSelect } from './GeoMultiSelect';
import { GeoNumberInput } from './GeoNumberInput';
import { ReferenceWrapper } from './reference/ReferenceWrapper';
import { useCopyToClipboard } from '../../../../../hooks/useCopyToClipboard';
import { AppendPreview } from './AppendPreview';
import { GeoBetMedia } from './GeoBetMedia';
import { BetMediaModal } from './BetMediaModal';
import { GeoDescription } from './GeoDescription';

interface Props {
	rowItem: { id: string; value: any; inherited: any };
	disabled?: boolean;
}

export const GeoRow: FC<Props> = ({ rowItem, disabled = false }) => {
	const { t } = useTranslation();
	const { id, value, inherited } = rowItem;

	const { fieldMap, updateGeoParameters, initialGeoParameters, modifiedParams } = useGeo();
	const { label, field_type_specifics, field_type } = fieldMap[id];
	let { data_type = field_type, value_rule } = field_type_specifics;
	if (field_type === 'REFERENCE') {
		data_type = 'REFERENCE';
	}
	if (field_type === 'BET_MEDIA') {
		data_type = 'BET_MEDIA';
	}
	if (field_type === 'HTML_TEXT') {
		data_type = 'HTML_TEXT';
	}

	const [locked, setLocked] = useState(value === null);
	const lockedClass = locked || disabled ? 'locked' : '';

	useEffect(() => {
		setLocked(value === null);
	}, [value]);

	const handleLocked = (e: ChangeEvent<HTMLInputElement>) => {
		const checked = e.target.checked;

		let value = initialGeoParameters.entity_params[id] || initialGeoParameters.inherited_params[id];
		if (value_rule && value_rule === 'APPEND') value = initialGeoParameters.entity_params[id];

		if (checked) updateGeoParameters(id, value);
		else updateGeoParameters(id, null);
		setLocked(!checked);
	};

	const [editing, edit] = useState(data_type === 'CSV');
	const [descriptionShown, showDescription] = useState(false);
	const activeClass = descriptionShown || editing ? 'active' : '';
	const labelClass = modifiedParams.hasOwnProperty(id) ? 'font_700' : '';

	const { copy } = useCopyToClipboard();

	const copyValue = () => {
		const val = value === null ? inherited : value;
		copy(val || '');
	};

	const component = () => {
		switch (data_type) {
			case 'float':
				return <GeoNumberInput locked={lockedClass} rowItem={rowItem} />;
			case 'int':
				return <GeoNumberInput locked={lockedClass} rowItem={rowItem} />;
			case 'text':
				return <GeoTextInput locked={lockedClass} rowItem={rowItem} />;
			case 'bool':
				return <GeoRadio locked={lockedClass} rowItem={rowItem} />;
			case 'select':
				return <GeoSelect locked={lockedClass} rowItem={rowItem} specifics={field_type_specifics} />;
			case 'multi_select':
				return <GeoMultiSelect locked={lockedClass} rowItem={rowItem} specifics={field_type_specifics} />;
			case 'CSV':
				return <GeoCsv locked={lockedClass} rowItem={rowItem} />;
			case 'percentage':
				return <GeoPercentage locked={lockedClass} rowItem={rowItem} />;
			case 'REFERENCE':
				return <ReferenceWrapper locked={lockedClass} rowItem={rowItem} specifics={field_type_specifics} />;
			case 'BET_MEDIA':
				return <GeoBetMedia locked={lockedClass} rowItem={rowItem} />;
			case 'HTML_TEXT':
				return <GeoDescription locked={lockedClass} rowItem={rowItem} specifics={field_type_specifics} />;
			default:
				return null;
		}
	};

	// Field to show inherited values above active field
	const showAppendField = value_rule === 'APPEND' && !locked;

	return (
		<>
			{showAppendField ? <AppendPreview label={label} rowItem={rowItem} /> : null}
			<div className={`accordion_item  ${activeClass} ${disabled ? 'disabled' : ''}`}>
				<div className="accordion_header form_row">
					<div className="acc_label">
						<div className={`row_title ${labelClass}`}>{label}</div>
					</div>

					<div className="option">
						<label className="switch" htmlFor={id}>
							<input id={id} type="checkbox" checked={!locked} className="switch_mark" onChange={handleLocked} />
							<span className="slider"></span>
							<span className="checkbox_label">
								<span className="on_label">{t('geo.special')}</span>
								<span className="off_label">{t('geo.inherited')}</span>
							</span>
						</label>
					</div>

					{component()}

					<GeoActions
						edit={edit}
						copy={copyValue}
						editing={editing}
						data_type={data_type}
						showDescription={showDescription}
						descriptionShown={descriptionShown}
					/>
				</div>
				{descriptionShown ? <RowDescription id={id} /> : null}
				{data_type === 'CSV' && editing ? <CsvTable locked={lockedClass} rowItem={rowItem} /> : null}
				{data_type === 'BET_MEDIA' && editing ? (
					<BetMediaModal locked={locked} edit={edit} rowItem={rowItem} dataType={field_type_specifics.data_type} />
				) : null}
			</div>
		</>
	);
};
